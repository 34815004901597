<section class="resume-section" id="hacks">
  <div class="resume-section-content">
    <div class="header">
      <h2 class="mb-5">Hackathons</h2>
    </div>

    <div class="hacks-list">
      <div class="card" *ngFor="let hack of collapsed ? (hacks | slice: 0:6) : hacks" [style.backgroundColor]="hack.thumbColor" (click)="openHack(hack)" [id]="hack.title">
        <span *ngIf="hack.winner" class="ribbon"><i class="fas fa-trophy winner"></i></span>
        <div class="thumb" [style.backgroundImage]="'url(' + hack.thumb + ')'" [style.backgroundSize]="hack.thumbSize"></div>
        <div class="content">
          <span class="title">{{ hack.title }}</span>
          <span class="year">{{ hack.year }}</span>
        </div>
      </div>
    </div>

    <span href="#" *ngIf="collapsed" (click)="toggleCollapsed()" class="button" id="collapse"> <i class="fa fa-plus"></i>&nbsp;View More </span>
    <span href="#" *ngIf="!collapsed" (click)="toggleCollapsed()" class="button" id="collapse"> <i class="fa fa-minus"></i>&nbsp;View Less </span>
  </div>
</section>
<hr class="m-0" />
