<app-nav></app-nav>
<!-- Page Content-->
<div class="container-fluid p-0" style="position: relative">
  <!-- <div class="console">
    <div class="shell">
      <div class="toolbar">
        <i class="fa fa-circle" style="color: #db5863" aria-hidden="true"></i>&nbsp; <i class="fa fa-circle" style="color: #f1c62a" aria-hidden="true"></i>&nbsp;
        <i class="fa fa-circle" style="color: #48cd38" aria-hidden="true"></i>
      </div>

      <div class="commands">
        <span style="opacity: 0.8">$</span>&nbsp;
        <span>{{ lines }}</span>
        <span id="cursor" class="blink"></span>
      </div>
    </div>
  </div> -->
  <router-outlet></router-outlet>
  <!-- <app-home></app-home> -->
</div>
