<!-- About-->
<section class="resume-section" id="about">
  <div id="about-bg" class=""></div>
  <div class="resume-section-content">
    <h1 class="mb-0 focus-in-contract-bck">
      Akram
      <span class="text-primary">Ansari</span>
    </h1>
    <div class="subheading mb-5">Full Stack Developer · Video Streaming Research Student</div>
    <br/>
    <table class="metrics">
      <tr (click)="goto('hacks')" class="animate__animated animate__fadeInLeft" style="animation-delay: 1s">
        <!-- <td><i class="fas fa-mug-hot"></i></td> -->
        <td>
          <!-- <span>{{ counts.hacks.toString().padStart(2, "0") }}</span> -->
          <span>xx</span>
        </td>
        <td>
          Hackathons
          <i class="fas fa-long-arrow-alt-right"></i>
        </td>
      </tr>
      <tr (click)="goto('projects')" class="animate__animated animate__fadeInLeft" style="animation-delay: 1.2s">
        <!-- <td><i class="fas fa-project-diagram"></i></td> -->
        <td>
          <!-- <span>{{ counts.projects.toString().padStart(2, "0") }}</span> -->
          <span>xx</span>
        </td>
        <td>Projects <i class="fas fa-long-arrow-alt-right"></i></td>
      </tr>
      <tr (click)="goto('awards')" class="animate__animated animate__fadeInLeft" style="animation-delay: 1.4s">
        <!-- <td><i class="fas fa-trophy"></i></td> -->
        <td>
          <!-- <span>{{ counts.awards.toString().padStart(2, "0") }}</span> -->
          <span>xx</span>
        </td>
        <td>Awards <i class="fas fa-long-arrow-alt-right"></i></td>
      </tr>
    </table>
    <br />
    <div class="social-icons">
      <a class="social-icon animate__animated animate__bounceIn" style="animation-delay: 2.1s" href="https://www.linkedin.com/in/mdakram28/"><i class="fab fa-linkedin-in"></i></a>
      <a class="social-icon animate__animated animate__bounceIn" style="animation-delay: 2.2s" href="https://github.com/mdakram28/"><i class="fab fa-github"></i></a>
      <a class="social-icon animate__animated animate__bounceIn" style="animation-delay: 2.3s" href="https://github.com/mdakram28/"><i class="fab fa-facebook-f"></i></a>
    </div>

    <a id="scroll-icon" class="scroll-icon animate__animated" href="#projects"><i class="fas fa-chevron-down"></i></a>
  </div>
</section>
<hr class="m-0" />
<app-blogs-section></app-blogs-section>
<app-projects-section></app-projects-section>
<app-hacks-section></app-hacks-section>
