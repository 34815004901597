<div class="bg">
  <img [src]="blog.cover" />
</div>
<div id="blog" *ngIf="blog">
  <div class="cover" [style.backgroundImage]="'url(' + blog.cover + ')'"></div>
  <div class="content">
    <h1 class="title">{{ blog.title }}</h1>
    <div style="width: 100px; height: 1px; background-color: rgba(255, 255, 255, 0.753); margin: 15px 0px"></div>
    <span class="actions">
      <!-- <a class="github-button" href="https://github.com/{{ blog.repo }}" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star mdakram28/blog on GitHub">Star</a> -->

      <span class="date">{{ blog.updatedAt }}</span>
    </span>
    <br />
    <markdown [src]="blog.markdownSrc" class="markdown"></markdown>
    <!-- <a class="github-button" href="https://github.com/{{ blog.repo }}" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star mdakram28/blog on GitHub">Star</a> -->
  </div>
</div>
